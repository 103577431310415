/* eslint-disable no-unused-vars */
import { Web3GameService } from '../../services/Web3GameService';
import { PROJECTS } from '../../consts/Projects';
import { DataService } from '../oni-mansion-game/services/DataService';

function format_time(s) {
  const dtFormat = new Intl.DateTimeFormat('en-GB', {
    timeStyle: 'medium',
    timeZone: 'UTC',
  });

  return dtFormat.format(new Date(s * 1e3));
}

export default {
  name: 'GameHouseManagement',
  data() {
    return {
      playingProjects: [],
      babySpiritAddress: '',
      oniTransferFromAddress: '',
      oniTransferToAddress: '',
      oniTransferId: '',
      currentGameDay: 0,
      oniCoinBalance: 0,
      oniNFTAddress: 'not set',
      oniCoinAddress: 'not set',
      houseGameAddress: 'not set',
      hauntedHouseAddress: 'not set',
      houseGameContractAddress: '',
      hauntedHouseContractAddress: '',
      hauntedHouseMintedResolved: '',
      oniCoinContractAddress: '',
      addedNFTContractAddress: '',
      oniNFTContractAddress: '',
      nftContractAddressUpdate: '',
      oniIDGetAttr: '',
      eventDayHauntedGeneric: '',
      eventDayHauntedSpecific: '',
      nftIDUpdate: '',
      ownerAddressUpdate: '',
      missionCounterResolved: '',
      attrInPosition: '',
      nftAddressDailyCheckIn: '',
      nftIDDailyCheckIn: '',
      balanceOfCoinForAddress: '',
      minted: false,
      contractMissionCounter: '',
      nftIDOMissionCounter: '',
      lootBoxPositionChangeLoot: '',
      nftAddressChangeLoot: '',
      oniIDChangeLoot: '',
      attrIDForPrice: '',
      nftAddressClaimLoot: '',
      oniIDClaimLoot: '',
      lootBoxPositionClaimLoot: '',
      attrIDForPriceGet: '',
      attrPrice: '',
      attrPriceResolved: '',
      attrTypeResolved: '',
      attrIDForType: '',
      attrType: '',
      oniIdsSpecificHaunt: '',
      eventDaySetSpecificHaunt: '',
      oniTobeHauntedSpecific: '',
      amountOfMissionsForThatNFT: '',
      nftIDHauntedSpecific: '',
      nftContractHauntedSpecific: '',
      nftIDHauntedGeneric: '',
      nftContractHauntedGeneric: '',
      oniTobeHauntedGeneric: '',
      nftContractDailyBonus: '',
      oniIdToLootBoxPositionToLootedAttrIdResolved: '',
      oniItems: '',
      nftIDDailyBonus: '',
      nftContractWeeklyBonus: '',
      nftIDWeeklyBonus: '',
      contractDailyGenericGaunting: '',
      nftContractToBuyAttr: '',
      houseAttrIdToBuyAttr: '',
      ammountToBuyAttr: '',
      nftIDToBuyAttr: '',
      slotPositionToBuyAttr: '',
      nftIDDailyGenericGaunting: '',
      dayDailyGenericGaunting: '',
      contractDailySpecificGaunting: '',
      nftIDDailySpecificGaunting: '',
      dayDailySpecificGaunting: '',
      dailySpecificHauntingMissionResolve: '',
      dailyGenericHauntingMissionResolve: '',
      lootBoxItemInPosition: '',
      contractOpenLootBox: '',
      nftIDOpenLootBox: '',
      positionOpenLootBox: '',
      attrIdToSetPrice: '',
      attrIdToSetType: '',
      attrTypeToSet: '',
      attrPriceToSet: '',
      lastTimeCheckedIn: '',
      sideWindow: '',
      roofWindow: '',
      firstFrontWindow: '',
      secondFrontWindow: '',
      towerWindow: '',
      chimney: '',
      door: '',
      roof: '',
      yard: '',
      giveCoinsAddress: '',
      formInput: {
        account: '',
        amount: 1,
        tokenId: '',
      },
      ownerAddress: '',
      onis: null,
      /**
       * @type { Web3GameService }
       */
      web3Config: new Web3GameService(),
      account: null,
    };
  },
  methods: {
    accountsWatch() {
      if (this.web3Config.provider) {
        this.web3Config.provider.on('accountsChanged', (accounts) => {
          this.account = accounts[0];
          this.web3Config.account = accounts[0];
          this.getBalance();
        });
      }
    },
    getWeb3Config() {
      this.web3Config
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          this.accountsWatch();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async unpause() {
      this.web3Config.unpauseHouseGame();
    },
    async pause() {
      this.web3Config.pauseHouseGame();
    },
    async getLastDailyCheckIn(nftId, nftAddress) {
      let lastTimeCheckedIn = await this.web3Config.getLastDailyCheckIn(nftAddress, nftId);
      this.lastTimeCheckedIn = format_time(lastTimeCheckedIn - 10800);
    },
    async nftIdToLootBoxPositionToLootedAttrId(nftContractAddress, contractOpenLootBox, nftIDOpenLootBox) {
      let oniIdToLootBoxPositionToLootedAttrIdResolved = await this.web3Config.nftIdToLootBoxPositionToLootedAttrId(
        nftContractAddress,
        contractOpenLootBox,
        nftIDOpenLootBox
      );
      console.log(oniIdToLootBoxPositionToLootedAttrIdResolved);
      this.oniIdToLootBoxPositionToLootedAttrIdResolved = oniIdToLootBoxPositionToLootedAttrIdResolved.toString();
    },
    async getMissionCounter(nftAddress, nftId) {
      let amountOfMissionsForThatNFT = await this.web3Config.getMissionCounter(nftAddress, nftId);
      this.amountOfMissionsForThatNFT = amountOfMissionsForThatNFT;
    },
    async missionCounter(contractMissionCounter, nftIDOMissionCounter) {
      let missionCounterResolved = await this.web3Config.missionCounter(contractMissionCounter, nftIDOMissionCounter);
      this.missionCounterResolved = missionCounterResolved;
    },
    async nftToHouseSlotToAttrID(nftAddressHouseAttr, oniIDHouseAttr, attrPositionHouseAttr) {
      let attrInPosition = await this.web3Config.nftToHouseSlotToAttrID(
        nftAddressHouseAttr,
        oniIDHouseAttr,
        attrPositionHouseAttr
      );
      this.attrInPosition = attrInPosition;
    },
    async getHouseAttrs(nftcontractAddress, oniId) {
      let oniItems = await this.web3Config.getHouseAttrs(nftcontractAddress, oniId);
      this.oniItems = oniItems;
    },
    async updateHouseEquipedItems(nftAddressEquipedAttr, oniIDEquipedAttr) {
      let sideWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '1');
      let roofWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '2');
      let firstFrontWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '3');
      let secondFrontWindow = await this.web3Config.nftToHouseSlotToAttrID(
        nftAddressEquipedAttr,
        oniIDEquipedAttr,
        '4'
      );
      let towerWindow = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '5');
      let door = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '6');
      let chimney = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '7');
      let roof = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '8');
      let yard = await this.web3Config.nftToHouseSlotToAttrID(nftAddressEquipedAttr, oniIDEquipedAttr, '9');

      this.sideWindow = sideWindow;
      this.roofWindow = roofWindow;
      this.firstFrontWindow = firstFrontWindow;
      this.secondFrontWindow = secondFrontWindow;
      this.towerWindow = towerWindow;
      this.chimney = chimney;
      this.door = door;
      this.roof = roof;
      this.yard = yard;
    },
    async getOniCoinBalance(address) {
      let oniCoinBalance = await this.web3Config.getOniCoinBalance(address);
      this.oniCoinBalance = oniCoinBalance;
    },
    async getCurrentGameDay() {
      let currentGameDay = await this.web3Config.currentDayInt();
      this.currentGameDay = currentGameDay;
    },
    async getOniNFTAddress() {
      let oniAddress = await this.web3Config.getOniNFTAddress();
      this.oniNFTAddress = oniAddress;
    },
    async getOniCoinAddress() {
      let oniCoinAddress2 = await this.web3Config.getOniCoinAddress();
      this.oniCoinAddress = oniCoinAddress2;
    },
    async getHauntedHouseAddress() {
      let hauntedHouseAddress = await this.web3Config.getHauntedHouseAddress();
      this.hauntedHouseAddress = hauntedHouseAddress;
    },
    async alreadyMintedHouseForNFT(nftAddressHauntedHouseMinted, nftIDHauntedHouseMinted) {
      let hauntedHouseMintedResolved = await this.web3Config.alreadyMintedHouseForNFT(
        nftAddressHauntedHouseMinted,
        nftIDHauntedHouseMinted
      );
      this.hauntedHouseMintedResolved = hauntedHouseMintedResolved;
    },
    async getHouseGameAddress() {
      let houseGameAddress = await this.web3Config.getHouseGameAddress();
      this.houseGameAddress = houseGameAddress;
    },
    async getAttrPrice(attrID) {
      let attrPriceResolved = await this.web3Config.getAttrPrice(attrID);
      this.attrPriceResolved = attrPriceResolved;
    },
    async getAttrType(attrID) {
      let attrTypeResolved = await this.web3Config.getAttrType(attrID);
      this.attrTypeResolved = attrTypeResolved;
    },
    async dailyGenericHauntingMission(
      contractDailySpecificGaunting,
      nftIDDailySpecificGaunting,
      dayDailySpecificGaunting
    ) {
      let dailyGenericHauntingMissionResolve = await this.web3Config.dailyGenericHauntingMission(
        contractDailySpecificGaunting,
        nftIDDailySpecificGaunting,
        dayDailySpecificGaunting
      );
      this.dailyGenericHauntingMissionResolve = dailyGenericHauntingMissionResolve;
    },
    async dailySpecificHauntingMission(
      contractDailySpecificGaunting,
      nftIDDailySpecificGaunting,
      dayDailySpecificGaunting
    ) {
      let dailySpecificHauntingMissionResolve = await this.web3Config.dailySpecificHauntingMission(
        contractDailySpecificGaunting,
        nftIDDailySpecificGaunting,
        dayDailySpecificGaunting
      );
      this.dailySpecificHauntingMissionResolve = dailySpecificHauntingMissionResolve;
    },
    async openLootBox(contractOpenLootBox, nftIDOpenLootBox) {
      await this.web3Config.openLootBox(contractOpenLootBox, nftIDOpenLootBox);
    },
    async changeLootboxAttr(nftAddressChangeLoot, oniIDChangeLoot, lootBoxPositionChangeLoot) {
      await this.web3Config.changeLootboxAttr(nftAddressChangeLoot, oniIDChangeLoot, lootBoxPositionChangeLoot);
    },
    async claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot) {
      await this.web3Config.claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot);
    },
    async equipAttrToHouse(nftAddressEquipAttr, nftIDEquipAttr, attrIDEquipAttr, slopPositionIDEquipAttr) {
      await this.web3Config.equipAttrToHouse(
        nftAddressEquipAttr,
        nftIDEquipAttr,
        attrIDEquipAttr,
        slopPositionIDEquipAttr
      );
    },
    async setMissionCounter(nftAddressMissionCounter, oniIDMissionCounter, missionCounterInput) {
      await this.web3Config.setMissionCounter(nftAddressMissionCounter, oniIDMissionCounter, missionCounterInput);
    },
    async setNFTIdsSpecificMission(oniIds, eventDay, nftContractAddress) {
      oniIds = oniIds.split(',');
      await this.web3Config.setNFTIdsSpecificMission(oniIds, eventDay, nftContractAddress);
    },
    async setAttrsPriceBatch(attrIdToSetBatch, attrPriceToSetBatch) {
      attrIdToSetBatch = attrIdToSetBatch.split(',');
      attrPriceToSetBatch = attrPriceToSetBatch.split(',');
      await this.web3Config.setAttrsPriceBatch(attrIdToSetBatch, attrPriceToSetBatch);
    },
    async setAttrPrice(nftId, price) {
      await this.web3Config.setAttrPrice(nftId, price);
    },
    async setAttrType(nftId, type) {
      await this.web3Config.setAttrType(nftId, type);
    },
    async approveToStore() {
      await this.web3Config.approveToStore();
    },
    async setAttrType(attrIDForPrice, attrType) {
      await this.web3Config.setAttrType(attrIDForPrice, attrType);
    },
    async buyHouseAttrs(
      nftContractToBuyAttr,
      nftIDToBuyAttr,
      slotPositionToBuyAttr,
      houseAttrIdToBuyAttr,
      ammountToBuyAttr
    ) {
      await this.web3Config.buyHouseAttrs(
        nftContractToBuyAttr,
        nftIDToBuyAttr,
        slotPositionToBuyAttr,
        houseAttrIdToBuyAttr,
        ammountToBuyAttr
      );
    },
    async setAttrPrice(attrIDForPrice, attrPrice) {
      await this.web3Config.setAttrPrice(attrIDForPrice, attrPrice);
    },
    async setOniAddress(oniAddress) {
      await this.web3Config.setOniAddress(oniAddress.toString());
    },
    async setOniCoinAddress(oniCoinAddress) {
      await this.web3Config.setOniCoinAddress(oniCoinAddress.toString());
    },
    async setHauntedHouseAddress(hauntedHouseAddress) {
      await this.web3Config.setHauntedHouseAddress(hauntedHouseAddress.toString());
    },
    async setHouseGameAddress(houseGameAddress) {
      await this.web3Config.setHouseGameAddress(houseGameAddress.toString());
    },
    async hauntSpecificNFT(
      nftContractHauntedSpecific,
      nftIDHauntedSpecific,
      nftHaunteeContractAddress,
      oniTobeHauntedSpecific,
      eventDay
    ) {
      await this.web3Config.hauntSpecificNFT(
        nftContractHauntedSpecific,
        nftIDHauntedSpecific,
        nftHaunteeContractAddress,
        oniTobeHauntedSpecific,
        eventDay
      );
    },
    async hauntGenericOni(
      nftContractHauntedGeneric,
      nftIDHauntedGeneric,
      nftContractHaunteeGeneric,
      oniTobeHauntedGeneric,
      eventDay
    ) {
      await this.web3Config.hauntGenericOni(
        nftContractHauntedGeneric,
        nftIDHauntedGeneric,
        nftContractHaunteeGeneric,
        oniTobeHauntedGeneric,
        eventDay
      );
    },
    async claimDailyBonus(nftContractAddress, nftId) {
      await this.web3Config.claimDailyBonus(nftContractAddress, nftId);
    },
    async claimWeeklyBonus(nftContractAddress, nftId) {
      await this.web3Config.claimWeeklyBonus(nftContractAddress, nftId);
    },
    async addNFTContractAddress(nftContractAddress) {
      await this.web3Config.addNFTContractAddress(nftContractAddress.toString());
    },
    async doDailyCheckIn(nftAddress, nftID) {
      this.web3Config.doDailyCheckIn(nftAddress, nftID);
    },
    async updateNFTOwner(nftContractAddress, nftID, ownerAddress) {
      this.web3Config.updateNFTOwner(nftContractAddress, nftID, ownerAddress);
    },
    async updateOwners(nftContractAddresses, nftIDs, ownerAddresses) {
      nftContractAddresses = nftContractAddresses.split(',');
      nftIDs = nftIDs.split(',');
      ownerAddresses = ownerAddresses.split(',');
      this.web3Config.updateOwners(nftContractAddresses, nftIDs, ownerAddresses);
    },
    async unpauseOniCoin() {
      this.web3Config.unpauseOniCoin();
    },
    async pauseOniCoin() {
      this.web3Config.pauseOniCoin();
    },
    async mintCoins(address) {
      this.web3Config.mintCoins(address);
    },
    async mintBabySpirit(babySpiritAddress) {
      await this.web3Config.mintBabySpirit(babySpiritAddress.toString());
    },
    async unpauseBabySpirit() {
      await this.web3Config.unpauseBabySpirit();
    },
    async transferOni(from, to, id) {
      this.web3Config.transferOni(from, to, id);
    },

    async seedHouseAttributes() {
      const ds = new DataService();
      const data = await ds.getHouseAttributesContract();
      const ids = data.ids.split(',');
      const prices = data.value.split(',');
      console.log(ids, prices);
      await this.web3Config.setAttrsPriceBatch(ids, prices);
    },
  },
  mounted() {
    this.getWeb3Config();
    this.playingProjects = [];
    Object.keys(PROJECTS).forEach((key) => {
      const proj = {
        name: key,
        address: PROJECTS[key].contract_address,
      };
      this.playingProjects.push(proj);
    });
  },
};
