<template>
    <div class="background-card" style="display: flex; flex-direction: column; align-items: center; padding: 10px; ">
        <div v-for="item in playingProjects" :key="item.name" style="color: black">
            <label style="margin-bottom: 3px;">{{item.name}} Address: {{item.address}} </label>
        </div>
        <br>
        <br>
        <br>
        <ul style=" display: flex; flex-direction: column; color: black; border: 5px solid red; padding: 5px;">
            <li style="margin-bottom: 3px; padding: 5px;">Bruce: 0xFF43eD599B3c80fB71bb67c9D5452765e63d7a43 --- ID: 1  </li>
            <li style="margin-bottom: 3px;">Jonathan: 0xf5C9d16C74269DcD8281C91490fC69564372a55F --- ID: 2  </li>
            <li style="margin-bottom: 3px;">Pavan: 0x279f7364049B22Bb8A456532250c473e7b491619 --- ID: 3 </li>
            <li style="margin-bottom: 3px;">Veronica:  0x0000000000000000000000000000000000000000 --- ID: 4 </li>
            <li style="margin-bottom: 3px;">Nicolas : 0xad7595fb1b3dF0468522106312F9c968152059A7 --- ID: 5  </li>
            <li style="margin-bottom: 3px;">Eduardo: 0x3D940e2Ee4430C1fF1c8D345A70ba82ED3823E48 --- ID: 6 </li>
            <li style="margin-bottom: 3px;">Jason: 0xfd0fdE8D8cE02A614FD21F695093951ea0a6711C --- ID: 7 </li>
        </ul>
        <div style="color: black">
            <label style="margin-bottom: 3px; margin-right: 5px;">Oni Coin Balance: {{oniCoinBalance}} </label>
            <input type="text" v-model="balanceOfCoinForAddress">
            <button style="margin: 10px" @click="getOniCoinBalance(balanceOfCoinForAddress)">Update Balance</button> <br>
        </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <button style="margin: 10px" @click="getCurrentGameDay()">GET CURRENT GAME DAY</button> <br>
        <div style="color: black"> Current Game Day: {{currentGameDay}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <button style="margin: 10px" @click="addNFTContractAddress(addedNFTContractAddress)">
            Add NFT Contract Address to Play to Game
        </button> <br>
        <input type="text" v-model="addedNFTContractAddress">
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">Oni Coin Address </label>
            <input type="text" v-model="oniCoinContractAddress">
        </div>
        <button style="margin: 10px" @click="setOniCoinAddress(oniCoinContractAddress)">
            Set Oni Coin Address
        </button>
        <button style="margin: 10px" @click="getOniCoinAddress()">
            Get Oni Coin Address
        </button>
        <br>
        <div style="color: black"> Oni Coin Address: {{oniCoinAddress}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">House Game Address </label>
            <input type="text" v-model="houseGameContractAddress">
        </div>
        <button style="margin: 10px" @click="setHouseGameAddress(houseGameContractAddress)">
            Set House Game Address
        </button>
        <button style="margin: 10px" @click="getHouseGameAddress()">
            Get House Game Address
        </button>
        <br>
        <div style="color: black"> House Game Address: {{houseGameAddress}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">Haunted House Address </label>
            <input type="text" v-model="hauntedHouseContractAddress">
        </div>
        <button style="margin: 10px" @click="setHauntedHouseAddress(hauntedHouseContractAddress)">
            Set Haunted House NFT Address
        </button>
        <button style="margin: 10px" @click="getHauntedHouseAddress()">
            Get Haunted House Address
        </button>
        <br>
        <div style="color: black"> Haunted House Address: {{hauntedHouseAddress}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <button style="margin: 10px" @click="unpauseOniCoin()">
            Unpause Oni Coin
        </button>
        <button style="margin: 10px" @click="pauseOniCoin()">
            Pause Oni Coin
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px;">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">NFT Address </label>
            <input type="text" v-model="nftContractAddressUpdate">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">NFT ID </label>
            <input type="text" v-model="nftIDUpdate">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Owner Address </label>
            <input type="text" v-model="ownerAddressUpdate">
        </div>
        <button style="margin: 10px" @click="updateNFTOwner(nftContractAddressUpdate,nftIDUpdate,ownerAddressUpdate)">
            Update NFT Owner
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px;">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">NFT Addresses </label>
            <input type="text" v-model="nftContractAddressUpdateBatch">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">NFT IDS </label>
            <input type="text" v-model="nftIDUpdateBatch">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Owner Addresses </label>
            <input type="text" v-model="ownerAddressUpdateBatch">
        </div>
        <button style="margin: 10px" @click="updateOwners(nftContractAddressUpdateBatch, nftIDUpdateBatch,ownerAddressUpdateBatch)">
            Update NFT Owner Batch
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT Address </label>
            <input type="text" v-model="nftAddressDailyCheckIn">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDDailyCheckIn">
        </div>
        <button style="margin: 10px" @click="doDailyCheckIn(nftAddressDailyCheckIn, nftIDDailyCheckIn)">
            Daily Check In
        </button>
        <div style="color: black"> Last Time Perform Check in: {{lastTimeCheckedIn}} </div>
        <button style="margin: 10px" @click="getLastDailyCheckIn(nftAddressDailyCheckIn, nftIDDailyCheckIn)">
            Get Last Time Checked In
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Haunter Nft Contract Address </label>
            <input type="text" v-model="nftContractHauntedGeneric">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDHauntedGeneric">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Hauntee Nft Contract Address </label>
            <input type="text" v-model="nftContractHaunteeContractAddress">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID TO BE HAUNTED </label>
            <input type="text" v-model="nftTobeHauntedGeneric">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> EVENT DAY </label>
            <input type="text" v-model="eventDayHauntedGeneric">
        </div>
        <button style="margin: 10px" 
            @click="hauntGenericOni(nftContractHauntedGeneric, nftIDHauntedGeneric, nftContractHaunteeContractAddress, nftTobeHauntedGeneric, eventDayHauntedGeneric)">
            Haunt Generic Oni
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Oni IDS </label>
            <input type="text" v-model="oniIdsSpecificHaunt">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Event Day </label>
            <input type="text" v-model="eventDaySetSpecificHaunt">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT Address </label>
            <input type="text" v-model="nftAddressSetSpecificHaunt">
        </div>
        <button style="margin: 10px" 
            @click="setNFTIdsSpecificMission(oniIdsSpecificHaunt, eventDaySetSpecificHaunt, nftAddressSetSpecificHaunt)">
            Set IDS for Specific Haunt
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Nft Contract Address </label>
            <input type="text" v-model="nftContractHauntedSpecific">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDHauntedSpecific">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Nft Contract Address </label>
            <input type="text" v-model="haunteeContractHauntedSpecific">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID TO BE HAUNTED </label>
            <input type="text" v-model="oniTobeHauntedSpecific">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> EVENT DAY </label>
            <input type="text" v-model="eventDayHauntedSpecific">
        </div>
        <button style="margin: 10px" 
            @click="hauntSpecificNFT(nftContractHauntedSpecific, nftIDHauntedSpecific, haunteeContractHauntedSpecific, oniTobeHauntedSpecific, eventDayHauntedSpecific)">
            Haunt Specific Oni
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Nft Contract Address </label>
            <input type="text" v-model="nftContractDailyBonus">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDDailyBonus">
        </div>
        <button style="margin: 10px" 
            @click="claimDailyBonus(nftContractDailyBonus, nftIDDailyBonus)">
            Claim Daily Bonus
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Nft Contract Address </label>
            <input type="text" v-model="nftContractWeeklyBonus">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDWeeklyBonus">
        </div>
        <button style="margin: 10px" 
            @click="claimWeeklyBonus(nftContractWeeklyBonus, nftIDWeeklyBonus)">
            Claim Weekly Bonus
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="contractDailyGenericGaunting">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDDailyGenericGaunting">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Day </label>
            <input type="text" v-model="dayDailyGenericGaunting">
        </div>
        <button style="margin: 10px" 
            @click="dailyGenericHauntingMission(contractDailyGenericGaunting, nftIDDailyGenericGaunting, dayDailyGenericGaunting)">
            Daily Generic Mission
        </button>
        <div style="color: black"> Generic Mission: {{dailyGenericHauntingMissionResolve}} </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="contractDailySpecificGaunting">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDDailySpecificGaunting">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Day </label>
            <input type="text" v-model="dayDailySpecificGaunting">
        </div>
        <button style="margin: 10px" 
            @click="dailySpecificHauntingMission(contractDailySpecificGaunting, nftIDDailySpecificGaunting, dayDailySpecificGaunting)">
            Daily Specific Mission
        </button>
        <div style="color: black"> Specific Mission: {{dailySpecificHauntingMissionResolve}} </div>
    </div>

    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Attr ID </label>
            <input type="text" v-model="attrIdToSetPrice">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Ammount </label>
            <input type="text" v-model="attrPriceToSet">
        </div>
        <button style="margin: 10px" 
            @click="setAttrPrice(attrIdToSetPrice, attrPriceToSet)">
            set Attr price
        </button>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Attr ID </label>
            <input type="text" v-model="attrIdToSetType">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Type </label>
            <input type="text" v-model="attrTypeToSet">
        </div>
        <button style="margin: 10px" 
            @click="setAttrType(attrIdToSetType, attrTypeToSet)">
            set Attr type
        </button>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Attr ID </label>
            <input type="text" v-model="attrIDForPriceGet">
        </div>
        <button style="margin: 10px" @click="getAttrPrice(attrIDForPriceGet)">
            Get Attr Price
        </button>
        <div style="color: black"> Attr Price: {{attrPriceResolved}} </div>
        <button style="margin: 10px" @click="getAttrType(attrIDForPriceGet)">
            Get Attr Type
        </button>
        <div style="color: black"> Attr Type: {{ attrTypeResolved}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Attr IDs </label>
            <input type="text" v-model="attrIdToSetBatch">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Ammounts </label>
            <input type="text" v-model="attrPriceToSetBatch">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Attr Types </label>
            <input type="text" v-model="attrTypeToSetBatch">
        </div>
        <button style="margin: 10px" 
            @click="setAttrsPriceBatch(attrIdToSetBatch, attrPriceToSetBatch)">
            set batch
        </button>
        <div style="display: flex; flex-direction: column;">
            <div>1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50</div>
            <div>1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3,5,10,1,3</div>
            <div>1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5</div>
        </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; ">
        <button style="margin: 10px" @click="approveToStore()">Apporve to Store</button> <br>
        <div style="color: black"> Is address approved to Store: </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT Contract </label>
            <input type="text" v-model="nftContractToBuyAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDToBuyAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Slot Position </label>
            <input type="text" v-model="slotPositionToBuyAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> House Attr Id </label>
            <input type="text" v-model="houseAttrIdToBuyAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Ammount </label>
            <input type="text" v-model="ammountToBuyAttr">
        </div>
        <button style="margin: 10px" 
            @click="buyHouseAttrs(nftContractToBuyAttr, nftIDToBuyAttr, slotPositionToBuyAttr, houseAttrIdToBuyAttr, ammountToBuyAttr)">
            Buy Attr
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT contract Address </label>
            <input type="text" v-model="nftContractAddresGetHouseAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDGetAttr">
        </div>
        <button style="margin: 10px" 
            @click="getHouseAttrs(nftContractAddresGetHouseAttr, nftIDGetAttr)">
            Get my house attributes
        </button>
        <div style="color: black"> House attr IDS: {{ oniItems }} </div>
    </div>

    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="contractOpenLootBox">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDOpenLootBox">
        </div>
        <button style="margin: 10px" 
            @click="openLootBox(contractOpenLootBox, nftIDOpenLootBox)">
            Open Loot Box
        </button>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT contract </label>
            <input type="text" v-model="contractMissionCounter">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDOMissionCounter">
        </div>
        <button style="margin: 10px" 
            @click="missionCounter(contractMissionCounter, nftIDOMissionCounter)">
            Mission Counter for Oni
        </button>
        <div style="color: black"> Mission counter: {{missionCounterResolved}} </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Position </label>
            <input type="text" v-model="positionOpenLootBox">
        </div>
        <button style="margin: 10px" 
            @click="nftIdToLootBoxPositionToLootedAttrId(contractMissionCounter,nftIDOMissionCounter, positionOpenLootBox)">
            item id in position
        </button>
        <div style="color: black"> LootBox in Position: {{ oniIdToLootBoxPositionToLootedAttrIdResolved }} </div>
    </div>

    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressChangeLoot">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="oniIDChangeLoot">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Position </label>
            <input type="text" v-model="lootBoxPositionChangeLoot">
        </div>
        <button style="margin: 10px" 
            @click="changeLootboxAttr(nftAddressChangeLoot, oniIDChangeLoot, lootBoxPositionChangeLoot)">
            Change Loot Box item
        </button>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressClaimLoot">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="oniIDClaimLoot">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Position </label>
            <input type="text" v-model="lootBoxPositionClaimLoot">
        </div>
        <button style="margin: 10px" 
            @click="claimLootBoxItem(nftAddressClaimLoot, oniIDClaimLoot, lootBoxPositionClaimLoot)">
            Claim Loot Box item
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressHouseAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="oniIDHouseAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Position </label>
            <input type="text" v-model="attrPositionHouseAttr">
        </div>
        <button style="margin: 10px" 
            @click="nftToHouseSlotToAttrID(nftAddressHouseAttr, oniIDHouseAttr, attrPositionHouseAttr)">
            Id In position
        </button>
        <div style="color: black"> Attr ID In Position: {{ attrInPosition}} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressMissionCounter">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="oniIDMissionCounter">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Mission Counter </label>
            <input type="text" v-model="missionCounterInput">
        </div>
        <button style="margin: 10px" 
            @click="setMissionCounter(nftAddressMissionCounter, oniIDMissionCounter, missionCounterInput)">
            Set Mission Counter
        </button>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract To Get </label>
            <input type="text" v-model="nftAddressMissionCounterToGet">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID to Get </label>
            <input type="text" v-model="oniIDMissionCounterToGet">
        </div>
        <button style="margin: 10px" 
            @click="getMissionCounter(nftAddressMissionCounterToGet, oniIDMissionCounterToGet)">
            Get Mission Counter
        </button>
        <div style="color: black"> Number of Missions: {{ amountOfMissionsForThatNFT }} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressEquipedAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="oniIDEquipedAttr">
        </div>
        <button style="margin: 10px" 
            @click="updateHouseEquipedItems(nftAddressEquipedAttr, oniIDEquipedAttr)">
            update My House Equiped Items
        </button>
        <div style="display: flex; flex-direction: column; padding 5px;">
            <div style="color: black"> Side Window: {{sideWindow}}  </div>
            <div style="color: black"> Roof Window: {{roofWindow}}  </div>
            <div style="color: black"> First Front Window: {{firstFrontWindow}}  </div>
            <div style="color: black"> Second Front Window: {{secondFrontWindow}}  </div>
            <div style="color: black"> Tower Window: {{towerWindow}}  </div>
            <div style="color: black"> Chimney: {{chimney}}  </div>
            <div style="color: black"> Door: {{door}}  </div>
            <div style="color: black"> Roof: {{roof}}  </div>
            <div style="color: black"> Yard: {{yard}}  </div>
        </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressEquipAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDEquipAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> ATTR ID </label>
            <input type="text" v-model="attrIDEquipAttr">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Slot Position </label>
            <input type="text" v-model="slopPositionIDEquipAttr">
        </div>
        <button style="margin: 10px" 
            @click="equipAttrToHouse(nftAddressEquipAttr, nftIDEquipAttr, attrIDEquipAttr, slopPositionIDEquipAttr)">
            Equip Item
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Address </label>
            <input type="text" v-model="giveCoinsAddress">
        </div>
        <button style="margin: 10px" 
            @click="mintCoins(giveCoinsAddress)">
            Give 500 Coins
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Contract </label>
            <input type="text" v-model="nftAddressHauntedHouseMinted">
        </div>
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> NFT ID </label>
            <input type="text" v-model="nftIDHauntedHouseMinted">
        </div>
        <button style="margin: 10px" 
            @click="alreadyMintedHouseForNFT(nftAddressHauntedHouseMinted, nftIDHauntedHouseMinted)">
            Haunted House Minted for NFT
        </button>
        <div style="color: black"> Haunted House Minted For NFT: {{ hauntedHouseMintedResolved }} </div>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;"> Address </label>
            <input type="text" v-model="babySpiritAddress">
        </div>
        <button style="margin: 10px" @click="mintBabySpirit(babySpiritAddress)">
            Mint Baby Spirit
        </button>
        <button style="margin: 10px" @click="unpauseBabySpirit()">
            Unpause
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        This Button Seeds the House Attributes that are on DB to the Contract Mapping.
        <button style="margin: 10px" @click="seedHouseAttributes()">
            Seed Attributes
        </button>
    </div>
    <div class="background-card" style="display: flex; align-items: center; padding: 5px; ">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 3px;">
            <label style="margin-bottom: 3px;">Address</label>
            <input type="text" v-model="babyTransferFromAddress">
            <input type="text" v-model="babyTransferToAddress">
            <input type="text" v-model="babyTransferId">
        </div>
        <button
            style="margin: 10px"
            @click="transferOni(babyTransferFromAddress, babyTransferToAddress, babyTransferId)"
        >
            Transfer Oni
        </button>
    </div>
</template>
<script src="./GameHouseManagement.js"></script>
